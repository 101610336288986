import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout-archives"
import SEO from "../components/organisms/seo"

class categoryTemplate extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const edges = data.allMarkdownRemark.edges
    const categoryName = this.props.location.pathname.replace(/\//g,'').replace(/%20/g, ' ')

    return (
      <>
        <SEO title={`カテゴリー：${categoryName}の記事一覧 | ${siteTitle}`} />
        <Layout
          location={this.props.location}
          title={`カテゴリー：${categoryName}の記事リストです`}
          edges={edges}
          >
        </Layout>
      </>
    )
  }
}

export default categoryTemplate

export const pageQuery = graphql`
  query categoryList($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {category: {eq: $slug}}
      },
      sort: {
        fields: [fields___slug],
        order: DESC
      }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            digest
            category
            tag
            img {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            giphy
          }
          parent {
            ... on File {
              birthTime(formatString: "YYYY-MM-DD")
              modifiedTime(formatString: "YYYY-MM-DD")
            }
          }
        }
      }
    }
  }
`
